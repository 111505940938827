import React from 'react';
import Footer from '../Common/Footer';
import Banner from './../Segments/Banner';
import Header3 from '../Common/Header3';
import Footer2 from '../Common/Footer2';

var bnrimg = require('./../../images/background/bg.jpg');

class Screening extends React.Component {
    render() {
        return (
            <>
                <Header3 />
                <div className="page-content">
                    <Banner title="SCREENING" pagename="SCREENING" bgimage={bnrimg.default} />
                    <div className="section-full p-tb90 square_shape1 square_shape3 tm-blog-single-wrap">
                        {/* SECTION CONTENTG START */}
                        <div className="section-full p-tb80 tm-shortcode-wrap">
                            {/* LOCATION BLOCK*/}
                            <div className="container">
                                {/* TITLE START */}
                                <div className="section-head text-left text-black">
                                    <h2 className="text-uppercase font-36" style={{ textAlign: "center" }}>Tenant Screening Services</h2>
                                    <div className="wt-separator-outer">
                                        <div className="wt-separator bg-black" />
                                    </div>
                                </div>
                                <div className='max-mid-container'>
                                    <div className='wt-post-title'>
                                        <h2 className='post-title'>
                                            <span className='text-black font-20 letter-spacing-1 font-weight-600'>
                                                At Howard Realty Partners, we understand the importance of thorough tenant screening. Our comprehensive services are designed to provide property owners with peace of mind, knowing their investment is secure.
                                            </span> </h2>

                                    </div>
                                    <div className='wt-post-title'>
                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Background Checks: </span>We conduct detailed background checks to verify employment history, creditworthiness, and any criminal records. This ensures that prospective tenants are financially stable and responsible.</p>

                                        <p><span className='text-black font-14 letter-spacing-1 font-weight-600'>Rental History Verification: </span>Our team verifies potential tenants' rental histories, including references from previous landlords. This helps confirm their track record of timely rent payments and property care.</p>


                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Credit Checks: </span> We run credit assessments to gauge the financial stability of potential tenants, reducing the risk of lease defaults.</p>

                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Legal Compliance: </span> Our screening process adheres to all applicable laws and regulations, including fair housing and privacy laws.</p>
                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'> Tenant Selection: </span>Based on the screening results, we assist landlords in choosing tenants who are most likely to be responsible and reliable.</p>
                                        <p>Our commitment to our clients extends beyond property management; we aim to find tenants who are not only financially stable but also reliable and respectful. This comprehensive approach ensures that property owners can enjoy a hassle-free, profitable investment.</p>

                                    </div>
                                </div>

                                {/* TITLE END */}

                            </div>
                        </div>
                    </div>

                    {/* SECTION CONTENT END */}
                </div>


                <Footer2 />
            </>
        );
    };
};

export default Screening;