import React from 'react';

import Footer from '../Common/Footer';
import Banner from './../Segments/Banner';
import Header3 from '../Common/Header3';
import Footer2 from '../Common/Footer2';

var bnrimg = require('./../../images/banner/bg-maint.jpg');

class Tenant extends React.Component {
    render() {
        return (
            <>
                <Header3 />
                <div className="page-content">
                    <Banner title="TENANT" pagename="TENANT" bgimage={bnrimg.default} />
                    <div className="section-full p-tb90 square_shape1 square_shape3 tm-blog-single-wrap">
                        {/* SECTION CONTENTG START */}
                        <div className="section-full p-tb80 tm-shortcode-wrap">
                            {/* LOCATION BLOCK*/}
                            <div className="container">
                                {/* TITLE START */}
                                <div className="section-head text-left text-black">
                                    <h2 className="text-uppercase font-36" style={{ textAlign: "center" }}>Tenant Services at Howard Realty Partners</h2>

                                    <div className="wt-separator-outer">
                                        <div className="wt-separator bg-black" />
                                    </div>
                                </div>
                                <div className='max-mid-container'>
                                    <div className='wt-post-title'>
                                        <h2 className='post-title'>
                                            <span className='text-black font-20 letter-spacing-1 font-weight-600'>
                                                At Howard Realty Partners, we recognize that a property's success is deeply intertwined with its occupants. Our dedication extends beyond property acquisition, focusing on ensuring each property achieves its maximum potential.

                                            </span> </h2>
                                    </div>

                                    <div className='wt-post-title'>
                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'> Tenant Selection: </span>
                                            Selecting the right tenant is paramount. A reliable tenant not only ensures consistent rental income but also maintains the property's condition.


                                        </p>
                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'> Background Checks: </span>
                                            Every potential tenant undergoes a comprehensive background check, including financial assessments and rental histories. This rigorous process ensures our clients' properties are in trustworthy hands.


                                        </p>
                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'> Lease Negotiations: </span>
                                            Our team collaborates with tenants to negotiate favorable lease terms. This approach ensures a harmonious relationship, leading to longer tenancies and reduced turnovers.


                                        </p>
                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'> Ongoing Tenant Support: </span>
                                            Supporting tenants post-move-in is vital for a thriving property.


                                        </p>
                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'> Maintenance: </span>
                                            We promptly address maintenance requests, ensuring properties remain in prime condition and tenants are content.


                                        </p>
                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'> Communication: </span>
                                            Open channels of communication are maintained with all tenants. Whether it's lease queries or maintenance concerns, our team is always ready to assist.


                                        </p>
                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'> Inspections: </span>
                                            Regular property inspections are conducted. This proactive measure ensures properties are well-kept and any potential issues are swiftly addressed.


                                        </p>
                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'> The Howard Realty Partners Difference: </span>
                                            Partnering with Howard Realty Partners means entrusting your property to a team with a wealth of real estate experience. Our client-focused approach ensures each property we oversee is a prosperous endeavor.


                                        </p>
                                        <p>
                                            In essence, our tenant services are integral to our property management strategy. By prioritizing tenant satisfaction and support, we enhance the value and success of our clients' real estate investments.

                                        </p>



                                    </div>
                                </div>

                                {/* TITLE END */}

                            </div>
                        </div>
                    </div>

                    {/* SECTION CONTENT END */}
                </div>


                <Footer2 />
            </>
        );
    };
};

export default Tenant;