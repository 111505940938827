import React from 'react';
import Footer from '../Common/Footer';
import Banner from './../Segments/Banner';
import Header3 from '../Common/Header3';
import Footer2 from '../Common/Footer2';

var bnrimg = require('./../../images/main-slider/slider1/bg1.jpg');

class INVESTMENT_CONSULTING extends React.Component {
    render() {
        return (
            <>
                <Header3 />
                <div className="page-content">
                    <Banner title="INVESTMENT CONSULTING" pagename="INVESTMENT CONSULTING" bgimage={bnrimg.default} />
                    <div className="section-full p-tb90 square_shape1 square_shape3 tm-blog-single-wrap">
                        {/* SECTION CONTENTG START */}
                        <div className="section-full p-tb80 tm-shortcode-wrap">
                            {/* LOCATION BLOCK*/}
                            <div className="container">
                                {/* TITLE START */}
                                <div className="section-head text-left text-black">
                                    <h2 className="text font-36" style={{ textAlign: "center" }}>Investment Consulting</h2>
                                    <div className="wt-separator-outer">
                                        <div className="wt-separator bg-black" />
                                    </div>
                                </div>
                                <div className='max-mid-container'>
                                    <div className='wt-post-title'>
                                        <h2 className='post-title'>
                                            <span className='text-black font-20 letter-spacing-1 font-weight-400'>
                                                At Howard Realty Partners, we specialize in offering a diverse range of services designed to assist clients in making well-informed real estate investment decisions. Our investment consulting services equip clients with the knowledge and expertise required for strategic real estate investment.
                                            </span> </h2>
                                    </div>
                                    <div className='wt-post-title'>
                                        <h2 className='post-title'>
                                            <span className='text-black font-16 letter-spacing-1 font-weight-400'>
                                                We offer our investment consulting services on an hourly fee basis, allowing clients the flexibility to engage our services as per their specific needs, ensuring they only pay for the time we dedicate to their investment project.
                                            </span> </h2>
                                    </div>
                                    <div className='wt-post-title'>
                                        <h2 className='post-title'>
                                            <span className='text-black font-20 letter-spacing-1 font-weight-600'>

                                            </span> </h2>
                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>  Market Research and Analysis: </span>
                                            Gain insights into the current real estate market, identify potential investment opportunities, and receive comparable sales data for accurate pricing. Our team is adept at analyzing market trends, pinpointing emerging neighborhoods, and assessing market conditions.</p>

                                        <p><span className='text-black font-14 letter-spacing-1 font-weight-600'>Investment Strategy Development: </span>
                                            Collaborate with our experts to craft a real estate investment strategy tailored to your goals, risk appetite, and budget. This encompasses setting investment criteria, evaluating potential investment properties, and formulating a comprehensive investment plan.</p>


                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Property Analysis and Due Diligence: </span>
                                            Ensure the viability of potential investment properties with our thorough property analysis and due diligence services. This includes financial statement analysis, property condition evaluations, and risk identification.</p>

                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Investment Performance Tracking: </span>Monitor the performance of your real estate investments and make informed adjustments to your strategy when necessary.</p>
                                        <br />
                                        <p>At Howard Realty Partners, we are deeply committed to our mission of delivering exceptional service. We are here to guide you from the moment you decide to invest, ensuring a swift and favorable return on your investment.</p>

                                    </div>

                                </div>

                                {/* TITLE END */}

                            </div>
                        </div>
                    </div>

                    {/* SECTION CONTENT END */}
                </div>


                <Footer2 />
            </>
        );
    };
};

export default INVESTMENT_CONSULTING;