import React from 'react';
import Footer from '../Common/Footer';
import Banner from './../Segments/Banner';
import Header3 from '../Common/Header3';
import Footer2 from '../Common/Footer2';

var bnrimg = require('./../../images/banner/bg-multi.jpg');

class Multi_family extends React.Component {
    render() {
        return (
            <>
                <Header3 />
                <div className="page-content">
                    <Banner title="MULTI-FAMILY INVESTMENT" pagename="MULTI-FAMILY INVESTMENT" bgimage={bnrimg.default} />
                    <div className="section-full p-tb90 square_shape1 square_shape3 tm-blog-single-wrap">
                        {/* SECTION CONTENTG START */}
                        <div className="section-full p-tb80 tm-shortcode-wrap">
                            {/* LOCATION BLOCK*/}
                            <div className="container">
                                {/* TITLE START */}
                                <div className="section-head text-left text-black">
                                    <h2 className="text font-36" style={{ textAlign: "center" }}>Multi-Family Investment</h2>
                                    <div className="wt-separator-outer">
                                        <div className="wt-separator bg-black" />
                                    </div>
                                </div>
                                <div className='max-mid-container'>
                                    <div className='wt-post-title'>
                                        <h2 className='post-title'>
                                            <span className='text-black font-20 letter-spacing-1 font-weight-600'>
                                                Howard Realty Partners boasts a specialization in multi-family investments. Our years of in-depth research have equipped us with the knowledge to guide our clients towards maximizing returns from such investments.
                                            </span> </h2>
                                    </div>

                                    <div className='wt-post-title'>
                                        <h2 className='post-title'>
                                            <span className='text-black font-20 letter-spacing-1 font-weight-600'>

                                            </span> </h2><br />
                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Market Knowledge and Insight: </span>
                                            With decades of experience, our firm possesses a profound understanding of the local multi-family property market. We can identify the best areas for investment and provide insights into potential growth regions.</p>

                                        <p><span className='text-black font-14 letter-spacing-1 font-weight-600'>Rental Reports: </span>
                                            Our tools empower investors to make informed decisions by staying updated on current market rental rates, ensuring optimal returns on investments.</p>


                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Estimate of Value: </span>
                                            Whether you're looking to buy or sell a multi-family investment, our professional value estimates, based on recent comparable property sales, provide a reliable benchmark.</p>

                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Off-Market Opportunities: </span>
                                            Gain exclusive access to properties not publicly listed, giving you an edge in securing deals before they become widely available.</p>
                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Due Diligence Assistance: </span>
                                            We support clients throughout the due diligence process, from financial reviews and property inspections to legal document assessments, ensuring a sound investment.</p>
                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Negotiation and Deal Structuring: </span>
                                            Our team advocates for you, negotiating favorable terms and structuring deals to maximize your return on investment.</p>
                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Property Management: </span>
                                            Through our affiliates, we offer property management services, ensuring timely rent collection, prompt issue resolution, and overall property maintenance.</p>

                                        <p>Investing goes beyond just the property; it's also about partnering with the right people. At Howard Realty Partners, we pride ourselves on being knowledgeable, reliable, and treating our clients like family.</p>

                                    </div>

                                </div>

                                {/* TITLE END */}

                            </div>
                        </div>
                    </div>

                    {/* SECTION CONTENT END */}
                </div>


                <Footer2 />
            </>
        );
    };
};

export default Multi_family;