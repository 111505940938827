import React from 'react';
import Footer from '../Common/Footer';
import Banner from './../Segments/Banner';
import Header3 from '../Common/Header3';
import Footer2 from '../Common/Footer2';

var bnrimg = require('./../../images/gallery/bg5.jpg');

class Buyers extends React.Component {
    render() {
        return (
            <>
                <Header3 />
                <div className="page-content">
                    <Banner title="Buyers" pagename="Buyers" bgimage={bnrimg.default} />
                    <div className="section-full p-tb90 square_shape1 square_shape3 tm-blog-single-wrap">
                        {/* SECTION CONTENTG START */}
                        <div className="section-full p-tb80 tm-shortcode-wrap">
                            {/* LOCATION BLOCK*/}
                            <div className="container">
                                {/* TITLE START */}
                                <div className='max-mid-container'>

                                    <div className='wt-post-title'>



                                    </div>
                                    <div className='wt-post-title'>
                                        <p> <span className='text-black font-20 letter-spacing-1 font-weight-600'>Financing Solutions</span></p>
                                        <p>Howard Realty Partners is focused on providing an in-depth assessment of the needs and financial capacity of the client.</p>

                                        <p> <span className='text-black font-20 letter-spacing-1 font-weight-600'>Personalized Home Search:</span></p>
                                        <p>Howard Realty Partners is committed to understanding the individual needs and financial capacities of our clients. Leveraging a comprehensive real estate database, we offer detailed insights into the home buying process. Our services enable buyers to refine their search based on criteria such as location, property size, budget, and desired amenities. With the support of our experienced realtors, clients can navigate the home buying journey with confidence and ease, ensuring a seamless and rewarding experience.</p>

                                    </div>

                                </div>

                                {/* TITLE END */}

                            </div>
                        </div>
                    </div>

                    {/* SECTION CONTENT END */}
                </div>


                <Footer2 />
            </>
        );
    };
};

export default Buyers;