import React from 'react';

import Footer from '../Common/Footer';
import Banner from './../Segments/Banner';
import Header3 from '../Common/Header3';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Footer2 from '../Common/Footer2';

const images = [
    require('./../../images/sli.jpg'),
    require('./../../images/sli.jpg'),
    require('./../../images/sli.jpg'),
    require('./../../images/sli.jpg'),
    require('./../../images/sli.jpg'),
]

var bnrimg = require('./../../images/banner/bg-maint.jpg');

class Property_management extends React.Component {
    render() {
        const options = {
            loop: true,
            autoplay: true,
            autoplayTimeout: 3000,
            margin: 30,
            nav: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            items: 1,
            dots: false,
        };
        return (
            <>
                <Header3 />
                <div className="page-content">
                    <Banner title="PROPERTY-MANAGEMENT" pagename="PROPERTY-MANAGEMENT" bgimage={bnrimg.default} />
                    <div className="section-full p-tb90 bg-gray square_shape2 tm-about2-wrap">
                        <div className="container">
                            <div className="section-content ">
                                <div className="row">
                                    <div className="col-lg-5 col-md-6">
                                        <div className="m-about m-l50 m-r50">
                                            <OwlCarousel className="owl-carousel about-us-carousel owl-btn-bottom-right" {...options}>
                                                {images.map((item, index) => (
                                                    <div className="item" key={index}>
                                                        <div className="ow-img wt-img-effect zoom-slow">
                                                            <NavLink to={""}><img src={item.default} alt="" /></NavLink>
                                                        </div>
                                                    </div>

                                                ))}

                                            </OwlCarousel>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 ">
                                        <div className="m-about-containt  text-black p-t30">
                                            <span className="font-40 font-weight-700">Property Management</span>
                                            <h2 className="font-16">At Howard Realty Partners, we recognize the value and significance of your real estate investments. Our commitment is to ensure that every property under our management reaches its fullest potential, both in terms of financial returns and long-term sustainability.</h2>
                                            <div className='wt-post-title'>
                                                <h2 className='post-title'>
                                                    <span className='text-black font-20 letter-spacing-1 font-weight-600'>
                                                        Our Comprehensive Services Include:

                                                    </span> </h2>
                                            </div>
                                            <div className='wt-post-title'>
                                                <p> <span className='text-black font-14 letter-spacing-1 font-weight-800'> Tenant Acquisition: </span></p>
                                                <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'> Targeted Advertising: </span>
                                                    We utilize a mix of traditional and digital advertising channels to ensure your property reaches a wide audience.


                                                </p>
                                                <p><span className='text-black font-14 letter-spacing-1 font-weight-600'>    Rigorous Screening: </span>Every potential tenant undergoes a thorough background and financial check, ensuring reliability and long-term commitment.</p>
                                                <p><span className='text-black font-14 letter-spacing-1 font-weight-600'> Lease Negotiation: </span>
                                                    Our team works diligently to secure favorable lease terms that protect the interests of property owners.</p>


                                                <p><span className='text-black font-14 letter-spacing-1 font-weight-800'> Efficient Rent Collection: </span></p>


                                                <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Automated Systems: </span> We employ state-of-the-art systems that allow tenants to make payments seamlessly, reducing the likelihood of delays.

                                                </p>
                                                <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Proactive Follow-ups: </span> In the rare event of missed payments, our team follows up promptly, ensuring consistent cash flow for property owners.</p>

                                                <p> <span className='text-black font-14 letter-spacing-1 font-weight-800'>Maintenance & Upkeep: </span></p>
                                                <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Routine Inspections: </span>
                                                    Regular property checks ensure that minor issues are addressed before they escalate.</p>
                                                <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Network of Professionals: </span>
                                                    We have established partnerships with trusted contractors and service providers, guaranteeing quality work at competitive prices.</p>
                                                <p><span className='text-black font-14 letter-spacing-1 font-weight-800'> Building Strong Tenant Relations:</span></p>
                                                <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Open Communication: </span>
                                                    We believe in maintaining open lines of communication with tenants, addressing their concerns promptly.</p>
                                                <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Conflict Resolution: </span>
                                                    Our experienced team is skilled in mediating and resolving any disputes, ensuring a harmonious environment.</p>
                                                <p><span className='text-black font-14 letter-spacing-1 font-weight-800'> Transparent Financial Reporting:</span></p>
                                                <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Monthly Reports: </span>
                                                    Property owners receive detailed monthly reports, offering insights into income, expenses, and any outstanding issues.</p>
                                                <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Annual Reviews:  </span>
                                                    We conduct comprehensive annual reviews, providing a holistic view of the property's performance and suggesting areas of improvement.</p>
                                                <p>In the dynamic world of real estate, the right property management partner can make all the difference. At Howard Realty Partners, we combine our industry expertise with a personalized approach, ensuring that each property we manage not only meets but exceeds the expectations of our clients.</p>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* SECTION CONTENT END */}
                </div>


                <Footer2 />
            </>
        );
    };
};

export default Property_management;