import React from 'react';
import { NavLink } from 'react-router-dom';
import users from '../../data/users.json';
import { Link } from 'react-router-dom';

class Navigation extends React.Component {

    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/custom.js');

    };

    render() {
        return (
            <>
                <ul className=" nav navbar-nav">
                    <li className="">
                        <Link to={"/home"}>Home</Link>

                    </li>
                    <li className="has-child">
                        <Link to={"#"}>About</Link>

                        <ul className="sub-menu v-sub-menu">
                            <li className="has-child">

                                <Link to={"/aboutus"}>Our Team</Link>

                                <ul className="sub-menu">


                                    {users.map(item => (
                                        <li>
                                            <Link to={`/about/${item.name}`}>{item.name} {item.lastName}</Link>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                            <li >
                                <Link to={"/our-firm"}>Our Firm</Link>
                            </li>
                        </ul>
                    </li>
                    <li>

                        <Link to={"#"}>HOMEOWNERS</Link>
                        <ul className="sub-menu v-sub-menu">
                            <li><Link to={"/portfolio"}>Portfolio</Link></li>
                            <li><Link to={"/buyers"}>Buyers</Link></li>
                            <li><Link to={"/sellers"}>Sellers</Link></li>
                        </ul>
                    </li>


                    <li>
                        <Link to={"#"}>INVESTORS</Link>
                        <ul className="sub-menu v-sub-menu">
                            <li><Link to={"/investment-consulting"}>Investment Consulting</Link></li>
                            <li><Link to={"/single-family"}>Single Family Investment</Link></li>
                            <li><Link to={"/multi-family"}>Multi-Family Investment</Link></li>
                            <li><Link to={"/office-buildings"}>Office Buildings</Link></li>
                            <li><Link to={"/strip-retail"}>Strip Centers/Retail</Link></li>
                            <li><Link to={"/land-acquisitions"}>Land Acquisitions</Link></li>
                            <li><Link to={"/property-management"}>Property Management</Link></li>
                            <li><Link to={"/login-portal"}>Showcase</Link></li>
                        </ul>
                    </li>
                    <li>
                        <Link to={"/tenant"}>TENANTS</Link>
                        <ul className="sub-menu v-sub-menu">
                            <li><Link to={"/screening"}>Screening</Link></li>
                            <li><Link to={"/maintenance"}>Maintenance</Link></li>
                            <li><Link to={"/emergency"}>Emergency</Link></li>
                            <li><Link to={"/login-portal"}>Tenant Portal login</Link></li>
                        </ul>
                    </li>
                    <li className="submenu-direction">
                        <Link to={"/contactus"}>CONTACTS</Link>

                    </li>
                    <li className="submenu-direction">
                        <Link to={"/giving"}>GIVING</Link>

                    </li>
                    <li className="submenu-direction">
                        <Link to={"/careers"}>CAREERS</Link>

                    </li>
                </ul>
            </>
        );
    };
};

export default Navigation;