import React from 'react';

import Footer from '../Common/Footer';
import Banner from './../Segments/Banner';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Header3 from '../Common/Header3';
import Footer2 from '../Common/Footer2';

var bnrimg = require('./../../images/main-slider/slider1/bg2.jpg');

class Giving extends React.Component {
    render() {
        return (
            <>
                <Header3 />
                <div className="page-content">
                    <Banner title="GIVING" pagename="GIVING" bgimage={bnrimg.default} />
                    <div className="section-full p-tb90 square_shape1 square_shape3 tm-blog-single-wrap">
                        {/* SECTION CONTENTG START */}
                        <div className="section-full p-tb80 tm-shortcode-wrap">
                            {/* LOCATION BLOCK*/}
                            <div className="container">
                                {/* TITLE START */}
                                <div className="section-head text-left text-black">
                                    <h2 className="text-uppercase font-36" style={{ textAlign: "center" }}>Community Giving at Howard Realty Partners</h2>
                                    <div className="wt-separator-outer">
                                        <div className="wt-separator bg-black" />
                                    </div>
                                </div>
                                <div className='max-mid-container'>
                                    <div className='wt-post-title'>
                                        <p><span className='text-black font-14 letter-spacing-1 font-weight-800'>Our Focus Areas:</span>
                                        </p>
                                        <p><span className='text-black font-14 letter-spacing-1 font-weight-600'>Youth Empowerment: </span>
                                            We believe in nurturing the next generation. Our partnerships with non-profit organizations aim to uplift and empower young people, helping them become responsible citizens of tomorrow.
                                        </p>
                                        <p><span className='text-black font-14 letter-spacing-1 font-weight-600'>Community Support:</span>
                                            We are continuously working to provide support to organizations that share our advocacy for making the world a better place.

                                        </p>
                                        <p><span className='text-black font-14 letter-spacing-1 font-weight-800'>Partner Organizations:</span>
                                        </p>
                                        <p> Click <a href={'https://www.cycsf.org/'} style={{ color: 'red' }}>here</a> to visit for      <span className='text-black font-14 letter-spacing-1 font-weight-600'>Community Youth Center of San Francisco
                                        </span></p>

                                        <p> Click <a href={'https://www.foothillfamily.org/'} style={{ color: 'red' }}>here</a> to visit for
                                            <span className='text-black font-14 letter-spacing-1 font-weight-600'> Foothill Family
                                            </span></p>
                                        <p> Click <a href={'https://www.habitatla.org'} style={{ color: 'red' }}>here</a> to visit for
                                            <span className='text-black font-14 letter-spacing-1 font-weight-600'> Habitat for Humanity Greater Los Angeles
                                            </span></p>
                                        <br />
                                        <p>By aligning ourselves with organizations that share our values, we aim to create a lasting impact on the communities we serve. Our giving initiatives are a testament to our broader mission of creating positive change.</p>




                                    </div>


                                </div>


                                {/* TITLE END */}

                            </div>
                        </div>
                    </div>

                    {/* SECTION CONTENT END */}
                </div>


                <Footer2 />
            </>
        );
    };
};

export default Giving;