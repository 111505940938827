import React from 'react';

import Footer from '../Common/Footer';
import Banner from './../Segments/Banner';
import Header3 from '../Common/Header3';
import Footer2 from '../Common/Footer2';

var bnrimg = require('./../../images/banner/bg-maint.jpg');

class Maintenance extends React.Component {
    render() {
        return (
            <>
                <Header3 />
                <div className="page-content">
                    <Banner title="Maintenance" pagename="Maintenance" bgimage={bnrimg.default} />
                    <div className="section-full p-tb90 square_shape1 square_shape3 tm-blog-single-wrap">
                        {/* SECTION CONTENTG START */}
                        <div className="section-full p-tb80 tm-shortcode-wrap">
                            {/* LOCATION BLOCK*/}
                            <div className="container">
                                {/* TITLE START */}
                                <div className="section-head text-left text-black">
                                    <h2 className="text-uppercase font-36" style={{ textAlign: "center" }}>Maintenance Services</h2>
                                    <div className="wt-separator-outer">
                                        <div className="wt-separator bg-black" />
                                    </div>
                                </div>
                                <div className='max-mid-container'>
                                    <div className='wt-post-title'>
                                        <h2 className='post-title'>
                                            <span className='text-black font-20 letter-spacing-1 font-weight-600'>
                                                At Howard Realty Partners, we understand that effective property maintenance is crucial for both landlords and tenants. Our comprehensive services aim to address maintenance issues promptly and efficiently.
                                            </span> </h2>
                                    </div>
                                    <div className='wt-post-title'>
                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Prompt Response: </span>When tenants report maintenance issues, our team responds quickly to resolve the problem.</p>

                                        <p><span className='text-black font-14 letter-spacing-1 font-weight-600'>Coordination of Repairs: </span>We oversee all necessary repairs, ensuring they meet high standards and comply with laws and regulations.</p>


                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Follow-up: </span> After repairs, we follow up with tenants to confirm their satisfaction with the work done.</p>

                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Preventative Maintenance: </span> We offer guidance on routine tasks like changing air filters and checking smoke detectors to keep the property in top condition.</p>
                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'> 24/7 Emergency Maintenance: </span>For urgent issues like burst pipes or electrical outages, we provide round-the-clock emergency maintenance.</p>
                                        <p>Unlike many realty firms, our commitment extends beyond sales. We focus on comprehensive property management, including making tenants feel secure and comfortable in their homes. Our skilled team is available day and night to address any maintenance issues, ensuring peace of mind for both landlords and tenants.</p>

                                    </div>
                                </div>

                                {/* TITLE END */}

                            </div>
                        </div>
                    </div>

                    {/* SECTION CONTENT END */}
                </div>


                <Footer2 />
            </>
        );
    };
};

export default Maintenance;