import React from 'react';

import Footer from '../Common/Footer';
import Banner from './../Segments/Banner';
import Header3 from '../Common/Header3';
import Footer2 from '../Common/Footer2';

var bnrimg = require('./../../images/banner/bg-emer.jpg');

class Emergency extends React.Component {
    render() {
        return (
            <>
                <Header3 />
                <div className="page-content">
                    <Banner title="Emergency" pagename="Emergency" bgimage={bnrimg.default} />
                    <div className="section-full p-tb90 square_shape1 square_shape3 tm-blog-single-wrap">
                        {/* SECTION CONTENTG START */}
                        <div className="section-full p-tb80 tm-shortcode-wrap">
                            {/* LOCATION BLOCK*/}
                            <div className="container">
                                {/* TITLE START */}
                                <div className="section-head text-left text-black">
                                    <h2 className="text-uppercase font-36" style={{ textAlign: "center" }}>Emergency Services</h2>
                                    <div className="wt-separator-outer">
                                        <div className="wt-separator bg-black" />
                                    </div>
                                </div>
                                <div className='max-mid-container'>
                                    <div className='wt-post-title'>
                                        <h2 className='post-title'>
                                            <span className='text-black font-20 letter-spacing-1 font-weight-600'>
                                                At Howard Realty Partners, we understand that emergencies are unpredictable and can occur at any time. That's why we offer a range of emergency services designed to provide immediate assistance and peace of mind to both property owners and tenants.
                                            </span> </h2>
                                    </div>
                                    <div className='wt-post-title'>
                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>24/7 Emergency Response: </span>Tenants can reach out to us at any time, day or night, for immediate assistance in emergency situations.</p>

                                        <p><span className='text-black font-14 letter-spacing-1 font-weight-600'>Rapid Response Time:    </span>Our team responds swiftly to emergency requests, ensuring issues are addressed as quickly as possible. We have a network of trusted contractors for emergency repairs.</p>


                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Coordination of Emergency Repairs: </span>All emergency repairs are coordinated and supervised to meet high standards and legal requirements.</p>

                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Tenant Follow-up: </span>After emergency repairs, we follow up with tenants to confirm their satisfaction and ensure the property is safe and habitable.</p>
                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Tenant Safety and Security: </span>We prioritize the safety and well-being of tenants during emergencies and take all necessary steps to ensure a secure environment.</p>

                                        <p>Our commitment to excellence extends to emergency situations, where we aim to minimize damage and inconvenience. Rest assured, with Howard Realty Partners, you're in safe hands during any emergency.</p>


                                    </div>
                                </div>

                                {/* TITLE END */}

                            </div>
                        </div>
                    </div>

                    {/* SECTION CONTENT END */}
                </div>


                <Footer2 />
            </>
        );
    };
};

export default Emergency;