import React from 'react';
import Header2 from '../Common/Header2';
import Footer from '../Common/Footer';
import Banner from './../Segments/Banner';
import About2 from './../Segments/About2';
import TeamMembers2 from './../Segments/TeamMembers2';
import Services from './../Segments/Services';
import Story from './../Segments/Story';
import users from '../..//data/users.json'
import Footer2 from '../Common/Footer2';

var bnrimg = require('./../../images/banner/hollywood.jpg');

class OurFirm extends React.Component {

    componentDidMount() {


    }
    componentDidUpdate() {



    }
    render() {

        return (
            <>
                <div className="page-wraper">
                    <Header2 />

                    <div className="page-content">
                        <Banner title="Our Firm" pagename="OurFirm" bgimage={bnrimg.default} />
                        <div class="section-full p-tb90 square_shape1 square_shape3 tm-blog-single-wrap">
                            <div class="container">
                                <div class="max-mid-container">
                                    <div class="blog-post date-style-1 blog-detail text-black">
                                        <div class="wt-post-title ">
                                            <h1 class="text-black font-40 letter-spacing-1 font-weight-600 text-center">Howard Realty Partners, Inc.</h1>
                                        </div>
                                        <div class="wt-post-text">
                                            <p>Founded in 1975, Howard Realty Partners, Inc. emerged from the collaborative efforts of Henry Ran and Herbert Hawkins. Their partnership was rooted in mutual respect, shared knowledge, and a genuine passion for real estate.</p>
                                            <p>In the mid-1980s, Henry Ran, dedicated to his craft and father to Howard, was actively guiding buyers and sellers through their real estate endeavors. After arriving in the U.S. as an international student in 1983, Henry swiftly transitioned into the real estate sector, obtaining his salesperson's license by 1985.</p>
                                            <p>Henry's formative years in the industry were shaped by the guidance of Herbert Hawkins, a seasoned real estate professional based in Temple City, California, since 1946. Hawkins was known for his innovative advertising strategies and his commitment to nurturing and training his team of agents.</p>
                                            <p>Under Hawkins' mentorship, Henry took on a variety of residential and multi-family projects, gaining invaluable experience and insights into the industry.</p>
                                            <p>The legacy was further enriched when, in 2010, Howard Ran, a UCLA graduate, joined the family business. Bringing a fresh perspective and modern approach, Howard quickly became a key figure in the firm, emphasizing client relationships and exceptional service. His dedication and approachability have been pivotal in building trust and fostering long-term relationships.</p>
                                            <p>Today, Howard Realty Partners, Inc. stands as a testament to the dedication and hard work of its team members. With a storied history and a forward-looking approach, the firm serves clients across Los Angeles, Pasadena, Orange County, and San Francisco, always prioritizing their needs and aspirations.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer2 />
                </div>
            </>
        );
    };
};

export default OurFirm;